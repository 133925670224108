<template>
  <div class="contaners">
    <div @click="mineMessage" class="messageTitle">
      <img class="messageIcon" src="../../assets/phone2.png" />
      <div>联系客服</div>
    </div>
    <img src="../../assets/content_img.png" />
    <div class="from_box">
      <div class="from_info">提示：以下填写资料为《中华全国总工会会员证》所需资料，请根据实际填写！</div>
      <div class="from_title">工会会员入会表</div>
      <van-form>
        <Input :config="dataForm.username" />
        <Select :listArray="dataForm.sex.list" :config="dataForm.sex"></Select>
        <Input :config="dataForm.nation" />
        <Select :listArray="dataForm.politics.list" :config="dataForm.politics"></Select>
        <Select :listArray="dataForm.education.list" :config="dataForm.education "></Select>
        <Input :config="dataForm.identity" />
        <Input :config="dataForm.phone" />
        <Input :config="dataForm.address" />
        <div class="experience">
          <div class="title">工作经验</div>
          <textarea v-model="dataForm.experience.value" placeholder-class="exper" :placeholder="dataForm.experience.title"></textarea>
        </div>
        <div class="experience">
          <div class="title">备注</div>
          <textarea v-model="dataForm.remark.value" placeholder-class="exper" :placeholder="dataForm.remark.title"></textarea>
        </div>
      </van-form>
    </div>

    <div class="from_box1">
      <div class="from_info">提示：以下填写资料为《中华全国总工会会员证》所需资料，请根据实际填写！</div>
      <div class="from_title">工会报名物料收集</div>
      <div class="example">
        <div>申请人照片</div>
        <div class="example_text" @click="explam">点击查看示例></div>
      </div>
      <div class="identityCard">
        <div class="box_img">
          <!-- <van-uploader class="imgFlexd" v-if="fileList6.length==0" :before-read="beforeRead" v-model="fileList6" multiple :max-count="1" />-->
          <uploadImg :isNumber="isNumber" :isUser="isUser" :uploadData="uploadData" @uploadImg="uploadImgOne"></uploadImg>
         
          
        </div>
      </div>
      <div class="text">提示:上传照片为1寸红底冠冕照片,大小不的超过10M</div>
      <div class="id_img margins">
        <uploadImg :isNumber="isNumber1" :idCard="idCard" :uploadData="uploadData1" @uploadImg="uploadImgTwo"></uploadImg>
      </div>
      <div class="id_img">
        <uploadImg :isNumber="isNumber1" :idCard="idCard" :uploadData="uploadData1" @uploadImg="uploadImgThree"></uploadImg>
      </div>
      <div class="bottom_text">提示:请确保照片边角完整</div>
      <div class="bottom_text" style="color:#fff;letter-spacing: 10px;font-size:20px" @click="nameDetail">修改签名>></div>
      <div class="submit" @click="nethSubmit">确认修改</div>
    </div>

    <div class="pop_box" v-if="cloneImgShow">
      <div class="pop_img">
        <img src="../../assets/explam.png" />
      </div>
      <img @click="cloneBtn" class="cloneImg" src="../../assets/cloneIcon.png" />
    </div>
    <!-- 签字弹窗 -->
    <div class="signtureShow" v-if="signtureShow" @click="signtureShow">
      <div class="signtureShow_box">
        <catSignature :imgWidth="imgWidth" @cloneBtns="cloneBtns" :clone="clone" @signatureBtn="signatureBtn" :imgToken="imgToken" @cloneImg="cloneImg" @imgUblod="imgUblod"></catSignature>
      </div>
    </div>

    <!-- 提示框 -->
    <toast v-if="toastShow" :config="configData"></toast>
  </div>
</template>     
<script type="">
// let form = {
//     username:'',
//     nation:''
// }
import * as apiCommon from "@/api/common";
import select from "../../components/select";
import input from "../../components/input";
import toast from "../../components/toast";
import catSignature from "../../components/catSignature.vue";
import uploadImg from "../../components/uploadImg";
// import wx from "weixin-js-sdk";
import { Toast } from 'vant';
export default {
  data() {
    return {
      // 上传图片
      isNumber:true,
      isUser:true,
      idCard:true,
      isNumber1:false,
      uploadData: {
        getToken: "",
        imgConfig: "{ useCdnDomain: true }",
        num:1
      },
      uploadData1: {
        getToken: "",
        imgConfig: "{ useCdnDomain: true }",
        num:1,
        
      },
      value: "",
      popShow: false,
      dataForm: {
        username: {
          value: "",
          title: "姓名",
          text: "请输入姓名"
        },
        nation: {
          value: "",
          title: "民族",
          text: "请输入民族"
        },
        identity: {
          value: "",
          title: "身份证",
          text: "请输入身份证号"
        },
        phone: {
          title: "手机号",
          text: "请输入手机号",
          value: ""
        },
        address: {
          title: "居住地址",
          text: "请输入居住地址",
          value: ""
        },
        experience: {
          title: "工作经验",
          text: "请输入工作经验",
          value: ""
        },
        remark: {
          title: "备注",
          text: "请输入备注",
          value: ""
        },
        sex: {
          list: ["女", "男"],
          value: "",
          title: "性别",
          text: "点击选择性别",
          boxWidth: "width:230px"
        },
        politics: {
          list: ["群众", "团员", "党员"],
          value: "",
          title: "政治面貌",
          text: "点击选择政治面貌",
          boxWidth: "width:320px"
        },
        education: {
          list: ["初中", "高中", "专科", "本科", "研究生"],
          value: "",
          title: "学历",
          text: "点击选择学历",
          boxWidth: "width:320px"
        }
      },
      cloneImgShow: false,
      fileList6: [],
      fileList7: [],
      fileList8: [],
      imgToken: "",
      imgConfig: "{ useCdnDomain: true }",
      fromData: null,
      apply_id: "",
      configData: {
        title: ""
      },
      toastShow: false,
      payStatus: "",
      imgWidth: 645,
      signtureShow: false,
      clone: true,
      img: ""
    };
  },
  components: {
    Select: select,
    Input: input,
    toast: toast,
    catSignature: catSignature,
    uploadImg:uploadImg
  },
  created() {
    document.title = "工会报名信息填写";
    this.applyUser();
    this.getToken();
  },
  methods: {
    cloneBtns() {
      this.signtureShow = false;
    },
    uploadImgOne(img){
      this.fileList6 = img
    },
    uploadImgTwo(img){
      this.fileList7 = img
    },
    uploadImgThree(img){
      this.fileList8 = img
    },
    imgUblod(img, index) {
      console.log(1);
      this.img = img;

      if (index) {
        this.signtureShow = false;
        this.toastShow = true;
        this.configData.title = "签名完成";
        setTimeout(() => {
          this.toastShow = false;
        }, 1000);
      }
    },
    cloneImg() {
      (this.img = ""), localStorage.removeItem("imgName");
    },
    signatureBtn() {
      //  this.signtureShow = false
      this.toastShow = true;
      this.configData.title = "签名不能为空";
      setTimeout(e => {
        console.log(e);
        this.toastShow = false;
      }, 1000);
    },
    nameDetail() {
      this.signtureShow = true;
    },
    imgBtnClone(index) {
      console.log(index, "index");
      if (index == 0) {
        this.fileList6 = [];
      } else if (index == 1) {
        this.fileList7 = [];
      } else {
        this.fileList8 = [];
      }
    },

    // onSubmit(values) {
    //   console.log("submit", values);
    // },

    async applyUser() {
      let res = await apiCommon.getApplyUser();
      console.log(res, "res");
      if (res.code == 0) {
        let fromData = res.data.union_audit.audit_info;
        this.fromData = res.data.union_audit.audit_info;
        this.apply_id = res.data.union_audit.audit_id;
        this.dataForm.username.value = fromData.username;
        this.dataForm.nation.value = fromData.nation;
        this.dataForm.identity.value = fromData.identity;
        this.dataForm.phone.value = fromData.phone;
        this.dataForm.address.value = fromData.address;
        this.dataForm.experience.value = fromData.experience;
        this.dataForm.remark.value = fromData.remark;
        this.dataForm.sex.value = fromData.sex;
        this.dataForm.politics.value = fromData.politics;
        this.dataForm.education.value = fromData.education;
        this.fileList6 = [{ url: fromData.userImg }];
        this.fileList7 = [{ url: fromData.identityCard_front }];
        this.fileList8 = [{ url: fromData.identityCard_reverse }];
        console.log(
          this.dataForm.identity.value,
          "this.dataForm.identity.value"
        );
        this.$forceUpdate();
      }
    },
    nextBtn() {
      console.log(this.dataForm, "data");
      for (var i in this.dataForm) {
        if (i != "remark") {
          if (!this.dataForm[i].value.trim()) {
            this.toastShow = true;
            this.configData.title = this.dataForm[i].text;
            setTimeout(() => {
              this.toastShow = false;
            }, 1000);
            return false;
          }
        }
      }
      let obj = JSON.stringify(this.dataForm); //转化为JSON字符串
      localStorage.setItem("temp2", obj);
      this.$router.push("/lador/material");
    },
    async getToken() {
      let res = await apiCommon.getImgToken();
      if (!res || typeof res === "string" || res.error)
        return this.$toast.fail(res ? res.error || res : "获取七牛云信息失败!");
      this.imgToken = res.data;
      this.uploadData1.imgToken = res.data;
      this.uploadData.imgToken = res.data;
      console.log(this.uploadData.getToken, "1223131");
    },
    // 上传图片
    // 返回 Promise
    beforeRead(e) {
      Toast.loading({
        message: "加载中...",
        forbidClick: true
      });
      console.log(e, "file");
      let img = e;
      this.upload(1, img);
    },
    beforeRead1(e) {
      // Toast.loading({
      //   message: "加载中...",
      //   forbidClick: true
      // });
      console.log(e, "file");
      let img = e;
      this.upload(2, img);
    },
    beforeRead2(e) {
      
      console.log(e, "file");
      let img = e;
      this.upload(3, img);
    },
    async upload(index, img) {
      let that = this;
      let fd = new FormData();
      fd.append("token", that.imgToken);
      fd.append("config", that.imgConfig);
      fd.append("file", img);
      console.log(fd, "fd");
      let imgRes = await apiCommon.uploadImg(fd);
      that.imgUrl = "https://cdn.health.healthplatform.xyz/" + imgRes.key;
      if (index == 1) {
        that.fileList6 = [{ url: that.imgUrl }];
      } else if (index == 2) {
        that.fileList7 = [{ url: that.imgUrl }];
      } else {
        that.fileList8 = [{ url: that.imgUrl }];
      }
      console.log(that.fileList6, "fileList6", { type: "image/jpeg" });
      that.$forceUpdate();
    },
    explam() {
      this.cloneImgShow = true;
    },
    cloneBtn() {
      this.cloneImgShow = false;
    },
    mineMessage() {
      console.log(1, "2");
      //       wx.config({
      //    debug: false,
      //    appId: '',
      //    timestamp: ,
      //    nonceStr: '',
      //    signature: '',
      //    jsApiList: [
      //      // 所有要调用的 API 都要加到这个列表中
      //      'getLocation',
      //      'hideOptionMenu',
      //      'closeWindow'
      //    ]
      //  });
      // wx.ready(function() {
      // wx.closeWindow();
      this.$router.push("../lador/service");
      // });
    },
    async nethSubmit() {
      for (var i in this.dataForm) {
        console.log(i);
        if (i != "remark") {
          if (!this.dataForm[i].value.trim()) {
            this.toastShow = true;
            this.configData.title = this.dataForm[i].text;
            setTimeout(() => {
              this.toastShow = false;
            }, 1000);
            return false;
          }
        }
      }
      if (!/^1[3456789]\d{9}$/.test(this.dataForm.phone.value)) {
        this.toastShow = true;
        this.configData.title = "请输入正确的手机号";
        setTimeout(() => {
          this.toastShow = false;
        }, 1000);
        return false;
      }
      let img = [this.fileList6, this.fileList7, this.fileList8];
      for (var n = 0; n < img.length; n++) {
        console.log(n);
        if (img[n].length == 0) {
          this.toastShow = true;
          this.configData.title = "请上传图片";
          setTimeout(() => {
            this.toastShow = false;
          }, 1000);
          return false;
        }
      }
      let data = {
        audit_info: {
          username: this.dataForm.username.value,
          sex: this.dataForm.sex.value,
          nation: this.dataForm.nation.value,
          politics: this.dataForm.politics.value,
          education: this.dataForm.education.value,
          identity: this.dataForm.identity.value,
          phone: this.dataForm.phone.value,
          address: this.dataForm.address.value,
          experience: this.dataForm.experience.value,
          remark: this.dataForm.remark.value,
          signature: this.fromData.signature,
          userImg: this.fileList6[0].url,
          identityCard_front: this.fileList7[0].url,
          identityCard_reverse: this.fileList8[0].url
        }
      };
      if (this.img) {
        data.audit_info.signature = this.img;
      }
      let res = await apiCommon.detailsInfo(data, this.apply_id);
      if (res.code == 0) {
        this.$toast("修改成功");
        this.$router.go(-1);
      }
    }
  }
};
</script>
<style>
.messageTitle .messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.signtureShow {
  position: fixed;
  left: 30px;
  top: 100px;
  width: 645px;
  height: 900px;
  background-color: #fff;
}
.messageTitle {
  text-align: right;
  margin-right: 20px;
  font-size: 24px;
  margin-top: 20px;
  position: absolute;
  z-index: 10;
  top: 30px;
  right: 20px;
  color: #fff;
}
.box_clone {
  width: 40px;
  border-radius: 40px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
}
.box_clone .imgs {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  margin: 5px;
}
.header_btn {
  font-size: 50px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  margin: 40px auto;
}
.content {
  position: absolute;
  top: 30px;
  left: 0;
}
.contaners {
  width: 100%;
  height: 3000px;
}
.contaners img {
  width: 100%;
  height: 100%;
}
.from_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1600px;
}
.from_box1 {
  position: absolute;
  top: 1500px;
  left: 0;
  width: 100%;
  height: 1600px;
}
.from_info {
  font-size: 20px;
  color: #fff;
  margin-top: 20px;
  text-align: center;
}
.from_title {
  font-size: 50px;
  color: #fff;
  text-align: center;
  margin: 34px auto;
}

/* 覆盖样式 */
.van-cell {
  background-color: transparent;
  font-size: 25px;
  width: 626px;
}
.van-field__control {
  /* width: 428px; */
  height: 53px;
  border: 2px solid #ffffff;
  border-radius: 5px;
  padding-left: 15px;
  color: rgba(225, 225, 225, 0.5);
}
.van-field__label {
  width: 150px;
  height: 53px;
  line-height: 53px;
  text-align: right;
  color: #fff;
  font-size: 33px;
  margin-right: 20px;
}
.van-picker__cancel,
.van-picker__confirm {
  font-size: 30px;
}
.van-ellipsis {
  font-size: 30px;
}
.van-form > div {
  margin-bottom: 30px;
}
.experience {
  display: flex;
  font-size: 35px;
  color: #fff;
}
.title {
  width: 170px;
  text-align: right;
  margin-right: 20px;
}
textarea {
  width: 426px;
  height: 178px;
  border: 2px solid #ffffff;
  opacity: 1;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 25px;
  color: #fff;
}
.nextBtn {
  width: 200px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #2a7bb5;
  opacity: 1;
  background-color: #fff;
  border-radius: 45px;
  margin: 60px auto 0;
}

.exper {
  color: #fff;
}
.box_img {
  position: relative;
  width: 174px;
  height: 171px;
}
.box_img img {
  position: relative;
  width: 174px;
  height: 171px;
}
.imgFlexd {
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.van-uploader__wrapper {
  width: 100%;
  height: 100%;
}
.van-uploader__preview {
  width: 100%;
  height: 100%;
}
.van-uploader__preview-image {
  width: 100%;
  height: 100%;
}
.van-uploader__upload {
  width: 100%;
  height: 100%;
}
.van-uploader__input {
  bottom: 30px;
  left: 30px;
}
.van-icon-photograph:before {
  content: "";
}
.header_btn {
  font-size: 50px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  margin: 40px auto;
}

.van-uploader__preview-delete {
  width: 25px;
  height: 25px;
}
.content {
  position: absolute;
  top: 30px;
  left: 0;
}
.contaners img {
  width: 100%;
  height: 100%;
}
.from_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1600px;
}
.from_info {
  font-size: 20px;
  color: #fff;
  margin-top: 20px;
  text-align: center;
}
.from_title {
  font-size: 50px;
  color: #fff;
  text-align: center;
  margin: 34px auto;
}
.example {
  display: flex;
  justify-content: space-between;
  font-size: 30px;
  color: #fff;
  margin: 45px 26px 0 70px;
}
.example_text {
  font-size: 20px;
  border-bottom: 1px solid #fff;
  padding-bottom: 5px;
}
.identityCard {
  width: 174px;
  height: 171px;
  border: 2px dashed #fff;
  opacity: 1;
  border-radius: 5px;
  margin: 10px auto;
  font-size: 20px;
  text-align: center;
  color: #fff;
}
.identityCard .pic {
  width: 103px;
  height: 94px;
  margin: 30px auto 10px;
}
.text {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  margin: 17px auto 65px;
}
.id_img {
  width: 417px;
  height: 247px;
  margin: 0 auto 0;
  border:1px dashed #fff;
  position: relative;
}
.id_img img {
  width: 417px;
  height: 247px;
}
.margins {
  margin-bottom: 88px;
}
.bottom_text {
  font-size: 20px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 101px;
  color: #ffffff;
}
.submit {
  width: 200px;
  height: 60px;
  background-color: #fff;
  border-radius: 30px;
  text-align: center;
  line-height: 60px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #2a7bb5;
  opacity: 1;
  margin: 0 auto 0;
}
.pop_box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.6);
}
.pop_img {
  width: 600px;
  height: 1100px;
  margin: 70px auto 0;
}
.pop_img img {
  width: 100%;
  height: 100%;
}
.pop_box .cloneImg {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 110px;
  right: 100px;
}
.van-uploader__upload {
  background-color: transparent;
}
.fileImg {
  background-color: transparent !important;
  /* display: none */
  color: transparent !important;
  width: 174px;
  height: 171px;
}
</style>
